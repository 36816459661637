import './bootstrap';

// Import your modules
import { initImageModal } from './early-access/modal';
import earlyAccessForm from './early-access/early-access-form';
import placeData from './places';
import imageSlider from './components/imageSlider';

// Only import Alpine if it's not already defined
let Alpine;
if (!window.Alpine) {
    Alpine = (await import('alpinejs')).default;
    window.Alpine = Alpine;
}

// Make other components available globally
window.placeData = placeData;
window.imageSlider = imageSlider;

// Wait for Alpine to be available (either from our import or Livewire)
document.addEventListener('alpine:init', () => {
    // Register Alpine components
    window.Alpine.data('earlyAccessForm', earlyAccessForm);
    window.Alpine.data('imageSlider', imageSlider);
    window.Alpine.data('placeData', placeData);

    // Create stores
    window.Alpine.store('placeData', {
        selectedCategories: [],
        visibleCategories: new Set()
    });

    window.Alpine.store('categoryData', {
        visibleCategories: new Set()
    });
});

// Only start Alpine if we imported it (if it wasn't already available)
if (Alpine) {
    Alpine.start();
}

// Initialize other components
document.addEventListener('DOMContentLoaded', () => {
    initImageModal();
});
