export default function placeData() {
    return {
        showModal: false,
        showCategoryModal: false,
        categorySearch: '',
        selectedPlace: null,
        newComment: '',
        comments: [],
        isSubmitting: false,
        highlightedPlaceIndex: null,
        highlightedMarker: null,
        openIndex: null,
        places: window.placesData || [],
        selectedCategories: [],
        isFilterOpen: false,
        isLoading: true,
        viewMode: window.innerWidth >= 768 ? 'map' : 'list',  // Default to map on desktop, list on mobile
        characterCount: 0,
        clickedPlaceIndex: null,
        visibleCategoryCount: 5, // Default value

        getCategoryStats(categoryName) {
            const categoryPlaces = this.places.filter(place => place.category?.name === categoryName);
            const placeCount = categoryPlaces.length;
            const checkInsCount = categoryPlaces.reduce((sum, place) => sum + (place.check_ins_count || 0), 0);
            return {
                name: categoryName,
                placeCount,
                checkInsCount,
                activityScore: placeCount + (checkInsCount * 0.5)
            };
        },

        calculateVisibleCategories() {
            const container = this.$el.querySelector('.categories-container');
            if (!container) return;

            // Create temporary buttons to measure their widths
            const tempDiv = document.createElement('div');
            tempDiv.style.visibility = 'hidden';
            tempDiv.style.position = 'absolute';
            tempDiv.style.display = 'flex';
            document.body.appendChild(tempDiv);

            // Create temporary buttons for all categories
            const buttons = this.uniqueCategories.map(category => {
                const button = document.createElement('button');
                button.className = 'flex-shrink-0 px-3 py-1 text-sm rounded-full category-button whitespace-nowrap';

                const content = document.createElement('div');
                content.className = 'flex items-center';

                const img = this.getCategoryImage(category);
                if (img) {
                    const imgEl = document.createElement('img');
                    imgEl.src = img;
                    imgEl.className = 'inline-block w-4 h-4 mr-1 rounded';
                    content.appendChild(imgEl);
                }

                const span = document.createElement('span');
                span.textContent = category;
                content.appendChild(span);

                button.appendChild(content);
                tempDiv.appendChild(button);
                return button;
            });

            const containerWidth = container.offsetWidth;
            const moreButtonWidth = 80; // Width of "+X more" button
            const clearButtonWidth = this.selectedCategories.length > 0 ? 80 : 0;
            const availableWidth = containerWidth - moreButtonWidth - clearButtonWidth - 16; // 16px for container padding

            let totalWidth = 0;
            let visibleCount = 0;

            // Calculate how many buttons can fit
            for (let i = 0; i < buttons.length; i++) {
                const buttonWidth = buttons[i].offsetWidth + 8; // 8px for gap
                if (totalWidth + buttonWidth <= availableWidth) {
                    totalWidth += buttonWidth;
                    visibleCount++;
                } else {
                    break;
                }
            }

            // Cleanup
            document.body.removeChild(tempDiv);

            // Update the count
            this.visibleCategoryCount = Math.max(visibleCount, 1);
        },

        get uniqueCategories() {
            const categories = [...new Set(this.places.map(place => place.category?.name))].filter(Boolean);
            const categoriesWithStats = categories.map(category => this.getCategoryStats(category));
            categoriesWithStats.sort((a, b) => b.activityScore - a.activityScore);
            return categoriesWithStats.map(stat => stat.name);
        },

        get filteredCategories() {
            if (!this.categorySearch) return this.uniqueCategories;
            const search = this.categorySearch.toLowerCase();
            return this.uniqueCategories.filter(category =>
                category.toLowerCase().includes(search)
            );
        },

        getCategoryImage(categoryName) {
            const place = this.places.find(p => p.category?.name === categoryName);
            return place?.category?.image || null;
        },

        // Helper method to get category stats for display
        getCategoryDisplayInfo(categoryName) {
            const stats = this.getCategoryStats(categoryName);
            return {
                name: categoryName,
                placeCount: stats.placeCount,
                checkInsCount: stats.checkInsCount
            };
        },

        get filteredPlaces() {
            return this.selectedCategories.length > 0
                ? this.places.filter(place => this.selectedCategories.includes(place.category.name))
                : this.places;
        },
        truncateDescription(description) {
            return description.split(' ').slice(0, 5).join(' ') + (description.split(' ').length > 5 ? '...' : '');
        },
        updatePlaces(event) {
            this.places = event.detail;
            this.isLoading = false;
        },
        init() {
            // Ensure modals are hidden on initialization
            this.showModal = false;
            this.showCategoryModal = false;

            // Initialize selectedPlace as null
            this.selectedPlace = null;

            const placesListEl = document.getElementById('placesList');
            if (placesListEl) {
                placesListEl.addEventListener('update-places', this.updatePlaces.bind(this));
            }

            // Calculate initial visible categories after a short delay to ensure DOM is ready
            setTimeout(() => {
                this.calculateVisibleCategories();
            }, 0);

            // Add resize observer to handle container width changes
            const resizeObserver = new ResizeObserver(() => {
                this.calculateVisibleCategories();
            });

            this.$nextTick(() => {
                const container = this.$el.querySelector('.categories-container');
                if (container) {
                    resizeObserver.observe(container);
                }
            });

            document.addEventListener('map-bounds-changed', () => {
                this.$nextTick(() => {
                    this.$el.querySelector('.flex-wrap').innerHTML = this.$el.querySelector('.flex-wrap').innerHTML;
                });
            });

            this.$watch('showModal', (value) => {
                if (!value) {
                    this.clickedPlaceIndex = null;
                    this.clearHighlight();
                    setTimeout(() => {
                        this.selectedPlace = null;
                    }, 300);
                }
            });

            this.$watch('visibleCategories', (categories) => {
                Alpine.store('categoryData').visibleCategories = new Set(categories);
            });

            // Recalculate when categories change
            this.$watch('selectedCategories', () => {
                this.$nextTick(() => {
                    this.calculateVisibleCategories();
                });
            });

            // Also recalculate when window is resized
            window.addEventListener('resize', () => {
                this.calculateVisibleCategories();
            });
        },
        toggleHighlightPlace(index) {
            // If this is the same place and it's not from a click (clickedPlaceIndex is null), do nothing
            if (this.highlightedPlaceIndex === index && this.clickedPlaceIndex === null) {
                return;
            }

            // Clear any existing highlight first
            this.clearHighlight();

            // Set new highlight
            this.highlightedPlaceIndex = index;
            const placeDiv = document.getElementById(`place-${index}`);
            if (placeDiv) {
                placeDiv.classList.add('highlighted');
            }

            // Find and animate the marker
            if (window.markers) {
                // First, deselect any currently selected place
                if (typeof window.deselectPlace === 'function') {
                    window.deselectPlace();
                }

                const markerObj = window.markers.find(m => m.originalIndex === index);

                if (markerObj && markerObj.marker) {
                    // Ensure any existing animation is stopped
                    if (typeof markerObj.marker.stopBouncing === 'function') {
                        markerObj.marker.stopBouncing();
                    }

                    // Start new animation
                    if (typeof markerObj.marker.bounce === 'function') {
                        markerObj.marker.bounce({ duration: 500, height: 20 });
                        this.highlightedMarker = markerObj.marker;
                    }
                }
            }
        },
        async fetchComments() {
            if (!this.selectedPlace) return;
            try {
                const response = await fetch(`/places/${this.selectedPlace.id}/check-ins`);
                if (!response.ok) throw new Error('Failed to fetch check-ins');
                const data = await response.json();

                // Initialize check_ins if it doesn't exist
                if (!this.selectedPlace.check_ins) {
                    this.selectedPlace.check_ins = [];
                }

                // Update both the comments array and the selectedPlace check_ins
                this.comments = data;
                this.selectedPlace.check_ins = data;

                // Force Alpine to re-render
                this.$nextTick(() => {
                    this.selectedPlace = { ...this.selectedPlace };
                });
            } catch (error) {
                console.error('Error fetching check-ins:', error);
                // Initialize empty check-ins array on error
                this.selectedPlace.check_ins = [];
            }
        },
        async submitCheckIn() {
            if (!this.selectedPlace ||
                this.isSubmitting ||
                this.characterCount < 25 ||
                this.characterCount > 500) return;

            this.isSubmitting = true;
            try {
                const response = await fetch(`/places/${this.selectedPlace.id}/check-ins`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                    },
                    body: JSON.stringify({ comment: this.newComment })
                });

                if (!response.ok) {
                    throw new Error('Failed to submit check-in');
                }

                const newCheckIn = await response.json();

                // Initialize check_ins array if it doesn't exist
                if (!this.selectedPlace.check_ins) {
                    this.selectedPlace.check_ins = [];
                }

                // Add the new check-in to the beginning of the array
                this.selectedPlace.check_ins.unshift({
                    id: newCheckIn.id,
                    comment: newCheckIn.comment,
                    user_name: newCheckIn.user_name,
                    created_at: newCheckIn.created_at,
                    is_approved: true // Add this if you're using approval system
                });

                // Update check-ins count if it exists
                if (this.selectedPlace.check_ins_count !== undefined) {
                    this.selectedPlace.check_ins_count++;
                }

                // Update most recent check-in
                this.selectedPlace.most_recent_check_in = new Date().toLocaleString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                });

                // Clear the form
                this.newComment = '';
                this.characterCount = 0;

                // Force Alpine to re-render the check-ins list
                this.$nextTick(() => {
                    this.selectedPlace = { ...this.selectedPlace };
                });

            } catch (error) {
                console.error('Error submitting check-in:', error);
                alert('Failed to submit check-in. Please try again.');
            } finally {
                this.isSubmitting = false;
            }
        },
        showModalAndFetchComments(place) {
            if (!place) return;  // Guard against null place

            // Reset state first
            this.newComment = '';
            this.characterCount = 0;
            this.isSubmitting = false;
            this.comments = [];

            // Create a copy of the place object with initialized properties
            const placeWithCheckins = {
                ...place,
                check_ins: [],
                user: place.user || {},
                category: place.category || {},
                media: place.media || []
            };

            // Set selectedPlace and show modal
            this.selectedPlace = placeWithCheckins;
            this.showModal = true;

            // Find and store the index of the clicked place
            this.clickedPlaceIndex = this.places.findIndex(p => p.id === place.id);

            // Highlight the clicked place
            this.toggleHighlightPlace(this.clickedPlaceIndex);

            // Fetch comments after everything is initialized
            this.$nextTick(() => {
                this.fetchComments();
            });
        },
        toggleCategory(categoryId) {
            const index = this.selectedCategories.indexOf(categoryId);
            if (index === -1) {
                this.selectedCategories.push(categoryId);
            } else {
                this.selectedCategories.splice(index, 1);
            }
            // Update the global store
            Alpine.store('placeData').selectedCategories = this.selectedCategories;

            this.updateMapMarkers();
        },
        updateMapMarkers() {
            if (!window.markers || !window.map || !window.markerClusterGroup) {
                return;
            }

            // Clear all markers from the cluster group
            window.markerClusterGroup.clearLayers();

            // Filter and add markers based on selected categories
            window.markers.forEach(({ marker, place }) => {
                const isCategoryVisible = this.selectedCategories.length === 0 ||
                                        this.selectedCategories.includes(place.category.name);

                if (isCategoryVisible) {
                    window.markerClusterGroup.addLayer(marker);
                }
            });

            // Update the places list
            this.updateVisiblePlaces();
        },
        updateVisiblePlaces() {
            if (!window.map || !window.markers) return;

            const bounds = window.map.getBounds();
            const visibleMarkers = window.markers.filter(({ marker, place }) => {
                const isInBounds = bounds.contains(marker.getLatLng());
                const isCategoryVisible = this.selectedCategories.length === 0 ||
                                        this.selectedCategories.includes(place.category.name);
                return isInBounds && isCategoryVisible;
            });

            // Update visibility of place cards
            const allPlaces = document.querySelectorAll('[id^="place-"]');
            allPlaces.forEach(placeEl => {
                placeEl.style.display = 'none';
            });

            visibleMarkers.forEach(({ originalIndex }) => {
                const placeEl = document.getElementById(`place-${originalIndex}`);
                if (placeEl) {
                    placeEl.style.display = '';
                }
            });
        },
        get visibleCategories() {
            if (!window.map || !window.markers) {
                return this.places
                    .map(place => place.category?.name)
                    .filter(Boolean);
            }

            const bounds = window.map.getBounds();
            const visibleCategories = new Set(
                window.markers
                    .filter(({ marker }) => bounds.contains(marker.getLatLng()))
                    .map(({ place }) => place.category?.name)
                    .filter(Boolean)
            );

            return Array.from(visibleCategories);
        },
        isPlaceVisible(index) {
            if (!window.map || !window.markers) return true;
            const marker = window.markers.find(m => m.originalIndex === index)?.marker;
            return marker && window.map.getBounds().contains(marker.getLatLng());
        },
        clearCategories() {
            this.selectedCategories = [];
            Alpine.store('placeData').selectedCategories = [];
            this.updateMapMarkers();
        },
        clearHighlight() {
            // Only clear if this isn't the clicked place
            if (this.highlightedPlaceIndex !== this.clickedPlaceIndex) {
                if (this.highlightedPlaceIndex !== null) {
                    const placeDiv = document.getElementById(`place-${this.highlightedPlaceIndex}`);
                    if (placeDiv) {
                        placeDiv.classList.remove('highlighted');
                    }

                    if (this.highlightedMarker && typeof this.highlightedMarker.stopBouncing === 'function') {
                        this.highlightedMarker.stopBouncing();
                        this.highlightedMarker = null;
                    }

                    this.highlightedPlaceIndex = null;
                }
            }
        },
        closeModal() {
            // Hide the modal first
            this.showModal = false;

            // Clear state
            this.clickedPlaceIndex = null;
            this.clearHighlight();
            this.newComment = '';
            this.characterCount = 0;
            this.isSubmitting = false;
            this.comments = [];

            // Clear selectedPlace after the transition
            setTimeout(() => {
                this.selectedPlace = {
                    media: [],
                    check_ins: [],
                    category: {},
                    user: {}
                };
            }, 300);
        }
    }
}
